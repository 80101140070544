import React from 'react';
import PortableText from 'react-portable-text';
import { BgImage } from 'gbimage-bridge';
import { Link, navigate } from 'gatsby';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Button, Container, useMediaQuery } from '@material-ui/core';

import { WaveUpHero } from '../../WaveSVGs/WaveUpHero';
import { HeroBody } from '../../CustomStyles/Typography';

const useStyles = makeStyles((theme) => ({
	text: {
		color: theme.white,
		marginBottom: '1rem',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
    },
	},
  rowContainerNoPageContent: {
    paddingBottom: '5rem',
		[theme.breakpoints.down('md')]: {
      paddingTop: '2rem',
      paddingBottom: '2rem',
		},
		[theme.breakpoints.down('xs')]: {
      paddingBottom: '0',
		},
  },
  rowContainer: {
    paddingTop: '2rem',
    marginBottom: '-1rem',
		[theme.breakpoints.down('md')]: {
      paddingTop: '2rem',
      paddingBottom: '2rem',
		},
		[theme.breakpoints.down('xs')]: {
      paddingBottom: '0',
		},
  },
	header: {
		lineHeight: 1.1,
		//marginTop: '50px',
		// [theme.breakpoints.down('md')]: {
		// 	marginTop: '0px',
		// },
		[theme.breakpoints.down('xs')]: {
			fontSize: '2.2rem',
			marginTop: '20px',
		},
	},
	imgCont: {
		padding: '6rem 0 8rem',
		maxWidth: '100vw',
		height: '100%',
		overflowX: 'hidden',
		backgroundSize: 'cover',
    backgroundPosition: 'bottom right',
		background:
			'linear-gradient(0deg, rgba(248, 251, 255, 0.8), rgba(248, 251, 255, 0.8)), linear-gradient(0deg, #F8FBFF, #F8FBFF), #F8FBFF',
		[theme.breakpoints.down('md')]: {},

		[theme.breakpoints.down('xs')]: {
			padding: '3rem 0',
			backgroundPosition: 'right',
		},
	},
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    
  },
  ctaButton: {
    textTransform: 'uppercase',
    margin: '2rem 0 4rem',
    textDecoration: 'none',
    color: '#2a7abc',
  },
}));

export const Hero = ({ hero, pageContent }) => {
	const classes = useStyles();
  const lg = useMediaQuery('(max-width: 1280px)');
  const sm = useMediaQuery('(max-width: 599px)');
  const theme = useTheme();
  const accentColor = theme.lightBlue;

	return (
		<>
			<BgImage
				className={classes.imgCont}
				image={hero?.backgroundImage?.asset?.gatsbyImageData}
				style={{
					lineHeight: '0',
					overflow: 'hidden',
          paddingBottom: sm ? '3rem' : '4rem',
				}}>
				<Container fixed style={{ position: 'relative' }}>
					<Grid container direction='row' justifyContent='center' className={ !pageContent ? classes.rowContainerNoPageContent : classes.rowContainer}>
						<Grid
							container
							item
							direction='column'
							justify='flex-start'
							alignItems='center'
							xs={12}
							md={9}>
							{hero?._rawContent.map((content, index) => (
								<PortableText
									key={index}
									content={content}
									className={classes.text}
									serializers={{
										h1: ({ children }) => (
											<Typography variant='h1' className={classes.header}>
												{children}
											</Typography>
										),
										h2: ({ children }) => (
											<Typography variant='h2' className={classes.header}>
												{children}
											</Typography>
										),
										h4: ({ children }) => (
											<Typography variant='h4' className={classes.header}>
												{children}
											</Typography>
										),
										normal: ({ children }) => <HeroBody>{children}</HeroBody>,
									}}
								/>
							))}{' '}
              {hero.ctaText && (
                <div className={classes.buttonContainer}>
                  <a href={ hero.ctaLink ? hero.ctaLink : '#'} style={{ textDecoration: 'none' }}>
                    <Button
                      variant='contained'
                      onClick={!hero.ctaLink ? (e) => navigate(-1) : null}
                      size='large'
                      className={classes.ctaButton}>
                      {hero.ctaText}
                    </Button>
                  </a>
                </div>
              )}
						</Grid>
					</Grid>
				</Container>
        {pageContent ? (
        <WaveUpHero height='213' width='100%' fill='white' />
        ) : null }
			</BgImage>
		</>
	);
};
